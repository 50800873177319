<template>
  <li v-bind:class="[deleted ? 'cd-cart__product--deleted' : '']" class="cd-cart__product">
    <div class="cd-cart__image">
      <router-link tag="img" :to="`/order/variation/${id}`" :src="getIcon(category)" style="padding: 8px;"></router-link>
    </div>
    <div class="cd-cart__details d-flex align-items-center">
      <router-link tag="h3" :to="`/order/variation/${id}`" class="truncate noline">{{name}}</router-link>
      <span class="cd-cart__price">{{formattedPrice}}</span>
      <div class="cd-cart__actions">
        <a v-on:click="deleteItemUndo()" class="cd-cart__delete-item">Delete</a>
        <div class="cd-cart__quantity">
          <label>Qty</label>
          <span class="cd-cart__select">
            <select v-model="itemCount" class="reset">
              <option v-for="option in (count + 5)" :value="option" :key="option">{{option}}</option>
            </select>
            <svg class="icon" viewBox="0 0 12 12">
              <polyline fill="none" stroke="currentColor" points="2,4 6,8 10,4 " />
            </svg>
          </span>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import $ from 'jquery';
import icons from '../assets/js/icons';
import Dinero from "dinero.js"

export default {
  props: {
    id: { required: true, type: String },
    name: { required: true, type: String },
    price: { required: true, type: Number },
    count: { required: true, type: Number },
    category: { required: true, type: String }
  },
  data() {
    return {
      cart: [],
      formattedPrice: '',
      itemCount: 1,
      deleted: false
    };
  },
  methods: {
    getIcon: function(name) {
      return icons[name];
    },
    updateItemCount(newCount) {
      this.$shoppingCart.setCountForItem(this.id, newCount);
    },
    deleteItem: function() {
      this.$shoppingCart.removeItemFromCartAll(this.id);
    },
    formatPrice(price) {
      return Dinero({ amount: price, currency: 'GBP' }).toFormat()
    },
    deleteItemUndo() {
      const undo = $('.js-cd-cart').find('.cd-cart__undo')[0]
      $(undo).addClass('cd-cart__undo--visible');

      clearInterval(window.timeout);
      this.deleted = true;

      setTimeout(() => {
        this.$shoppingCart.removeItemFromCartAll(this.id);
      }, 200);

      window.timeout = setTimeout(() => {
        $(undo).removeClass('cd-cart__undo--visible');
        $(undo).off('click');
      }, 10000);

      $(undo).off('click').on('click', () => {
        this.$shoppingCart.addItemToCart(this.id, this.name, this.price, this.itemCount, this.category);
        $(undo).removeClass('cd-cart__undo--visible');
      });
    }
  },
  watch: {
    itemCount: function(newValue) {
      this.updateItemCount(newValue);
      this.formattedPrice = this.formatPrice(this.price * newValue);
    }
  },
  mounted() {
    this.itemCount = this.count;
    this.formattedPrice = this.formatPrice(this.price * this.count);
  },
};
</script>