<template>
  <div v-bind:class="[hideCart ? 'cd-cart--hidden' : '', emptyCart ? 'cd-cart--empty' : '', cartOpen ? 'cd-cart--open' : '']" class="cd-cart js-cd-cart">
    <a @click="cartOpen = !cartOpen" class="cd-cart__trigger text-replace">
      Cart
      <ul class="cd-cart__count">
        <li>0</li>
        <li>0</li>
      </ul>
    </a>

    <div class="cd-cart__content">
      <div class="cd-cart__layout">
        <header class="cd-cart__header">
          <span class="header_left">
            <h2 class="p-0 m-0">Cart</h2>
            <a v-on:click="clearCart()" class="p-0 m-0 text-danger btn" href="#0">Clear Cart</a>
          </span>
          <span class="cd-cart__undo">
            Item removed.
            <a href="#0">Undo</a>
          </span>
        </header>

        <div class="cd-cart__body">
          <ul>
            <template v-if="cart.length > 0">
              <div v-for="item in cart" :key="item.id">
                <CartItem :id="item.id" :name="item.name" :price="item.price" :count="item.count" :category="item.category"></CartItem>
              </div>
            </template>
            <template v-else>
              <li class="text-center">
                <h3>Your cart is empty</h3>
              </li>
            </template>
          </ul>
        </div>

        <footer class="cd-cart__footer">
          <router-link class="cd-cart__checkout" tag="div" :to="cart.length > 0 ? `/order/pay` : ''">
            <em>
              Checkout -
              <span>{{totalCart}}</span>
              <svg class="icon icon--sm" viewBox="0 0 24 24">
                <g fill="none" stroke="currentColor">
                  <line stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="3" y1="12" x2="21" y2="12" />
                  <polyline stroke-width="2" stroke-linecap="round" stroke-linejoin="round" points="15,6 21,12 15,18 " />
                </g>
              </svg>
            </em>
          </router-link>
        </footer>
      </div>
    </div>
    <!-- .cd-cart__content -->
  </div>
</template>

<script>
import $ from 'jquery';
import Dinero from "dinero.js"
import CartItem from './CartItems.vue';

export default {
  components: {
    CartItem
  },
  data() {
    return {
      cart: [],
      totalCart: '£0.00',
      hideCart: false,
      emptyCart: false,
      cartOpen: false,
      itemDeleted: false
    }
  },
  methods: {
    loadCart(val) {
      // Get variables for cart
      const cartCount = this.$shoppingCart.totalCount();
      const cartTotal = this.$shoppingCart.totalCart();

      // Format cart total and update values
      this.totalCart = Dinero({ amount: cartTotal, currency: 'GBP' }).toFormat();

      // Update counter
      this.updateCounter(cartCount);

      // Update cart
      this.cart = val;
    },
    updateCounter(val) {
      $('.cd-cart__count').addClass('cd-cart__count--update')

      var actual = val
      var next = actual + 1

      window.setTimeout(function() {
        $('.cd-cart__count').find('li:nth-child(1)').text(actual)
      }, 150)

      window.setTimeout(function() {
        $('.cd-cart__count').removeClass('cd-cart__count--update')
      }, 200)

      window.setTimeout(function() {
        $('.cd-cart__count').find('li:nth-child(2)').text(next)
      }, 230)
    },
    clearCart() {
      this.cartOpen = false

      window.setTimeout(() => {
        this.$shoppingCart.clearCart();
      }, 600)
    },
  },
  computed: {
    countCart() {
      var totalCount = 0
      for (var item in this.cart) {
        totalCount += this.cart[item].count
      }
      return totalCount
    },
    cartTotal() {
      var total = 0
      for (var item in this.cart) {
        total += this.cart[item].price * this.cart[item].count
      }
      return total
    }
  },
  watch: {
    '$store.state.cart.shoppingCart': {
      handler: function(val) {
        console.log('loaded cart')
        this.loadCart(val);
      },
      deep: true
    }
  },
  mounted() {
    this.loadCart(this.$store.getters.getCart);
  }
}

</script>
